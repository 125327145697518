//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      info: {},
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    getInfo() {
      this.$axios
        .post(this.$api.samecity.service.orderInfo, {
          id: this.$route.query.id,
        })
        .then(res => {
          if (res.code == 0) {
            let info = res.result.info;
            let consignee_conditions = info.extend_order_extend.consignee_conditions;
            let consignee_conditions_text = '';
            if (consignee_conditions) {
              if (consignee_conditions.height == 1) {
                consignee_conditions_text += '施工高度大于三米、';
              }
              if (consignee_conditions.procedures == 1) {
                consignee_conditions_text += '要办理施工手续';
              }
              if (consignee_conditions.time == 1) {
                consignee_conditions_text += '白天施工';
              } else {
                consignee_conditions_text += '晚上施工';
              }
              info.consignee_conditions_text = consignee_conditions_text;
            }
            this.info = info;
          }
        });
    },
  },
};
